import React from 'react';

const Breadcrumb = ({ folderPath, onNavigate }) => {
  return (
    <nav aria-label="breadcrumb">
      <ul style={{ listStyleType: 'none', display: 'flex', gap: '5px' }}>
        {folderPath.map((folder, index) => (
          <li key={index} style={{ display: 'inline' }}>
            <button
              style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}
              onClick={() => onNavigate(index)}
            >
              {folder.name}
            </button>
            {index < folderPath.length - 1 && <span> / </span>}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
