import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcrumb from './Breadcrumb';
import { ClipLoader } from 'react-spinners'; // Import a spinner library (like react-spinners or any other)

const backend_url = "https://drive-navigator-914312986344.us-central1.run.app";
// const backend_url = "http://localhost:5000";

function App() {
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderPath, setFolderPath] = useState([{ id: 'root', name: 'Root' }]);  // State for breadcrumb navigation
  const placeholderImage = 'https://via.placeholder.com/180';  // Placeholder for files without thumbnails

  useEffect(() => {
    axios.get(`${backend_url}/api/profile`, { withCredentials: true })
      .then(response => {
        setUser(response.data);
      })
      .catch(() => {
        setUser(null);
      });
  }, []);

  const fetchFilesAndFolders = (folderId = 'root', folderName = 'root') => {
    axios.get(`${backend_url}/api/files?folderId=${folderId}`, { withCredentials: true })
      .then(response => {
        const filesData = response.data;
        const folders = filesData.filter(file => file.mimeType === 'application/vnd.google-apps.folder');
        const regularFiles = filesData.filter(file => file.mimeType !== 'application/vnd.google-apps.folder');

        setFolders(folders);
        setFiles(regularFiles);
      })
      .catch(err => {
        console.error('Error fetching files', err);
      });
  };

  useEffect(() => {
    fetchFilesAndFolders();
  }, []);

  const handleLogin = () => {
    window.open(`${backend_url}/auth/google`, '_self');
  };

  const handleLogout = () => {
    window.open(`${backend_url}/logout`, '_self');
  };

  const handleFolderClick = (folderId, folderName) => {
    setFolderPath([...folderPath, { id: folderId, name: folderName }]);
    fetchFilesAndFolders(folderId, folderName);
  };

  const handleBreadcrumbClick = (index) => {
    const selectedFolder = folderPath[index];
    setFolderPath(folderPath.slice(0, index + 1));
    fetchFilesAndFolders(selectedFolder.id);
  };

  const handleDownloadFile = (fileId) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = `${backend_url}/api/download-file?fileId=${fileId}`;
    // link.target = '_blank';
    link.rel = 'noopener noreferrer';
  link.setAttribute('download', '');

    // Append link to body and trigger click to initiate download
    document.body.appendChild(link);
    link.click();
    
    // Clean up by removing the link element
    document.body.removeChild(link);
  };

//   const handleDownloadFile = (fileId) => {
//     const response =  axios.get(`${backend_url}/api/download-file?fileId=${fileId}`, {
//       responseType: 'blob', // Important: Set the response type to 'blob' for binary data
//       withCredentials: true, // Include credentials if necessary
//     })
//         .then((res) => {
//             console.log(res);
//             res.blob()
//             // fileDownload(res.data, 'filename.png');
//             download(res.data, "file.png", res.headers['content-type']);
//         }).catch((error) =>{
//             console.error(error);
//             // message.error('upload failed.');
//     });
// }

// const handleDownloadFile = async (fileId) => {
//   try {
//     // Make a request to the backend to download the file
//     const response = await axios.get(`${backend_url}/api/download-file?fileId=${fileId}`, {
//       responseType: 'blob', // Important: Set the response type to 'blob' for binary data
//       withCredentials: true, // Include credentials if necessary
//     });

//     // Create a new Blob object using the response data
//     const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
//     // Create a link element to trigger the download
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''); // Extract filename from Content-Disposition
//     document.body.appendChild(link);
    
//     // Programmatically trigger the download
//     link.click();
    
//     // Clean up by revoking the Blob URL and removing the link element
//     URL.revokeObjectURL(link.href);
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error('Error downloading file:', error);
//     alert('Failed to download file. Please try again.');
//   }
// };  

  const handleProcessFile = (fileId) => {
    const updatedFiles = files.map(file =>
      file.id === fileId ? { ...file, loading: true } : file
    );
    setFiles(updatedFiles);

    axios.post(`${backend_url}/api/process-file`, { fileId }, { withCredentials: true })
      .then(response => {
        const updatedFiles = files.map(file =>
          file.id === fileId ? { ...file, loading: false, result: response.data.result } : file
        );
        setFiles(updatedFiles);
      })
      .catch(err => {
        console.error('Error processing file', err);
        const updatedFiles = files.map(file =>
          file.id === fileId ? { ...file, loading: false } : file
        );
        setFiles(updatedFiles);
      });
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column align-items-center p-4 bg-light">
      <h1 className="display-4 mb-5">Google Drive Navigator</h1>
      {user ? (
        <div className="w-100 max-w-6xl">
          <button className="btn btn-danger mb-4" onClick={handleLogout}>
            Logout
          </button>
          <Breadcrumb folderPath={folderPath} onNavigate={handleBreadcrumbClick} />

          {/* Folders Section */}
          <div>
            <h3 className="h5">Folders</h3>
            <div className="row">
              {folders.map(folder => (
                <div key={folder.id} className="col-md-3 p-2" >
                  <div
                    className="card text-center p-3 shadow-sm hover-shadow cursor-pointer"
                    onClick={() => handleFolderClick(folder.id, folder.name)}
                  >
                    <span className="text-primary">{folder.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Files Section */}
          <div className="row row-cols-3 gx-2 gy-2">
            {files.map(file => (
              <div key={file.id}>
                <div className="card shadow-sm">
                  <img
                    src={file.thumbnail ? `${backend_url}/api/proxy/thumbnail?thumbnailUrl=${encodeURIComponent(file.thumbnail)}&accessToken=1234` : placeholderImage}
                    alt={file.name}
                    className="card-img-top"
                    style={{ height: '240px', objectFit: 'cover', width: '100%' }}  // Ensures fixed height and covers the area
                  />

                  <div class="card-body">
                      <h6 class="card-title d-flex align-items-center">
                        <button
                          onClick={(event) => handleProcessFile(file.id, event)}
                          className="btn btn-light mr-1">
                          🔗
                        </button>
                        <button
                          onClick={() => handleDownloadFile(file.id)}
                          className="btn btn-light mr-1"
                        >
                          📥
                        </button> 
                        <span className="text-truncate flex-grow-1">{file.name}</span>

                      </h6>
                      <p class="card-text">{file.loading && (
                      <div className="d-flex justify-content-center">
                        <ClipLoader size={20} color={"#123abc"} loading={true} />
                      </div>
                    )}
                    {file.result && (
                      <div>
                         <button
                          onClick={() => navigator.clipboard.writeText(file.result)}
                          className="btn btn-light align-middle mr-2"
                        >
                          📋
                        </button>
                        
                        <span className="text-success text-truncate d-inline-block w-75 align-middle">{file.result}</span>
                       
                      </div>
                    )}
                    </p>
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <button className="btn btn-primary" onClick={handleLogin}>
          Login with Google
        </button>
      )}   
    </div>
  );
}

export default App;
